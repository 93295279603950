import React from 'react'
import Layout from '../../components/Default/Layout'
import PageHeader from '../../components/PageHeader/PageHeader'
import '../../components/ParagraphUnderPhoto/styles.css'
import Accordion from '../../components/Accordion/Accordion'
import AccordionTemplate from '../../components/Services/AccordionTemplate'
import Content from '../../../content/accordion/fu-hleda-data'
import { graphql } from 'gatsby'
import { H2Blue, UnderlineYellow } from '../../components/Typography'
const Vyhledavacicinnost = ({ data: { page } }) => {
  const { frontmatter } = page
  return (
    <Layout
      meta={page.frontmatter.meta || false}
      title={page.frontmatter.title || false}
    >
      <PageHeader
        title={frontmatter.title}
        backgroundImage={frontmatter.image.childImageSharp.fluid}
      />

      <div className="Paragraph--Wrapper container">
        <p>{frontmatter.description}</p>
        <H2Blue>FÚ hledá data</H2Blue>
        <UnderlineYellow />
        <p style={{ marginTop: '5.8rem' }}>
          Vyhledávací činností je činnost správce daně, při které vyhledává
          důkazní prostředky, jejichž prostřednictvím zjišťuje, zda daňové
          subjekty plní své daňové povinnosti. Uvedenou činnost může vykonávat
          nezávisle na jakémkoliv řízení (například na daňové kontrole nebo
          zajištění daně), před zahájením řízení i v jeho průběhu.
        </p>
        <p style={{ marginTop: '2.8rem' }}>
          Vyhledávací činnost není jednorázovou akcí správce daně, kdy by pouze
          prozkoumal,. zda je daný subjekt registrovaný k jednotlivým daním či
          zda odvedl daň ve správné výši. Správce daně může vyhledávací činnost
          provádět po celou dobu existence daňového subjektu.
        </p>
        <p style={{ marginTop: '2.8rem' }}>
          Jako zdroj slouží správci daně při vyhledávací činnosti různé
          evidence, registry, informační systémy nebo konkrétní osoby.
        </p>
        <p style={{ marginTop: '2.8rem' }}>
          V rámci vyhledávací činnosti správce daně provádí například ověření
          úplnosti evidence a registrace daňových subjektů nebo zjišťování údajů
          týkajících se příjmů, majetkových poměrů a dalších skutečností
          rozhodných pro správné zjištění, stanovení a placení daně.
        </p>
        <p style={{ marginTop: '2.8rem' }}>
          Vyhledávací činnost může správce daně provádět buď se součinností
          daňového subjektu či bez ní. Ač by se na první pohled mohlo zdát, že
          se v případech, kdy správce daně provádí vyhledávací činnost bez
          vědomí daňového subjektu, mohlo jednat o nezákonný postup („o nás bez
          nás“), není tomu tak.. Plná vědomost o prováděné vyhledávací činnosti
          by totiž mohla mít za následek maření opatření důkazních prostředků.
        </p>
        <p style={{ marginTop: '2.8rem' }}>
          V rámci vyhledávací činnosti může správce daně využít institutu podání
          vysvětlení nebo místního šetření.
        </p>
        <Accordion
          items={[
            {
              title: Content.vysvetleni.title,
              description: <AccordionTemplate {...Content.vysvetleni} />
            },
            {
              title: Content.mistniSetreni.title,
              description: <AccordionTemplate {...Content.mistniSetreni} />
            }
          ]}
        />
      </div>
      <br />
      <br />
    </Layout>
  )
}

export default Vyhledavacicinnost

export const pageQuery = graphql`
  query Vyhledavacicinnost {
    page: markdownRemark(frontmatter: { slug: { eq: "fu-hleda-data" } }) {
      ...Meta
      frontmatter {
        title
        description
        image {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
