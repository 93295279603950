const topicSlug = {
  vysvetleni: 'vysvetleni/',
  mistniSetreni: 'mistni-setreni/'
}

export const Content = {
  vysvetleni: {
    title: 'Co je to vysvětlení',
    slug: topicSlug.vysvetleni,
    description: `Vysvětlením se dle daňového řádu rozumí povinnost každé osoby poskytnout informace, kterými správce daně může ověřit skutečnosti rozhodné pro správné zjištění a stanovení daní a zabezpečení jejich úhrady. Je nutno podotknout, že tato povinnost se týká pouze třetích osob, tedy všech osob krom samotného daňového subjektu.`,
    photos: [],
    photosDescription: []
  },
  mistniSetreni: {
    title: 'Co je to místní šetření',
    slug: topicSlug.mistniSetreni,
    description: `Místní šetření je jednou z činností, kterou může správce daně provádět v rámci vyhledávací činnosti. Podstatou místního šetření  je skutečnost, že sevyhledávací činnost provádí v místě, kde se mohou nacházet důkazní prostředky.`
  }
}
export default Content
